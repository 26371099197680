export type TPersonalIdType = {
  name: string;
  regexp: RegExp;
  min: number;
  max: number;
};

export type TCountryPersonalIdTypes = {
  country: string;
  inputType: string;
  types: TPersonalIdType[];
};

export const countryPersonalIds: TCountryPersonalIdTypes[] = [
  {
    country: 'AR',
    inputType: 'numeric',
    types: [
      {
        name: 'DNI',
        regexp: /^[0-9]{7,9}$/,
        min: 7,
        max: 9
      },
      {
        name: 'CUIT',
        regexp: /^[0-9]{11}$/,
        min: 11,
        max: 11
      }
    ]
  },
  {
    country: 'BR',
    inputType: 'numeric',
    types: [
      {
        name: 'CPF',
        regexp: /^[0-9]{11}$/,
        min: 11,
        max: 11
      },
      {
        name: 'CNPJ',
        regexp: /^[0-9]{14}$/,
        min: 14,
        max: 14
      }
    ]
  },
  {
    country: 'CL',
    inputType: 'text',
    types: [
      {
        name: 'CI',
        regexp: /^[0-9]{8,9}$/,
        min: 8,
        max: 9
      },
      {
        name: 'RUT',
        regexp: /^[a-zA-Z0-9]{8,9}$/,
        min: 8,
        max: 9
      }
    ]
  },
  {
    country: 'CO',
    inputType: 'numeric',
    types: [
      {
        name: 'CC',
        regexp: /^[0-9]{6,10}$/,
        min: 6,
        max: 10
      }
    ]
  },
  {
    country: 'PE',
    inputType: 'numeric',
    types: [
      {
        name: 'DNI',
        regexp: /^[0-9]{8,9}$/,
        min: 8,
        max: 9
      }
    ]
  },
  {
    country: 'MX',
    inputType: 'text',
    types: [
      {
        name: 'CURP',
        regexp: /^[a-zA-Z0-9]{10,18}$/,
        min: 10,
        max: 18
      }
    ]
  },
  {
    country: 'UY',
    inputType: 'numeric',
    types: [
      {
        name: 'CI',
        regexp: /^[0-9]{6,8}$/,
        min: 6,
        max: 8
      }
    ]
  }
];
