import * as yup from 'yup';
import { StringSchema } from 'yup';
import { ICardHolder, IPersonalId, IPhoneNumber } from 'model/interfaces';
import { countryPersonalIds } from 'components/FormInputs/PersonalIdInput/types';
import { isValidPhoneNumber } from 'react-phone-number-input';

export type WithNull<T> = {
  [P in keyof T]: T[P] | null;
};

yup.setLocale({
  string: {
    email: 'messageError.invalidEmail'
  }
});

yup.addMethod<StringSchema>(yup.string, 'phone', function () {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: 'messageError.invalidPhone',
    test: value => {
      try {
        return isValidPhoneNumber(value);
      } catch (e) {
        return false;
      }
    }
  });
});

export const fullAddressCountries = ['CO'];

export const stateCountries = ['CO', 'AR'];

export const phoneSchema: yup.SchemaOf<IPhoneNumber> = yup.object({
  phone: yup.string().phone().required().label('review.mobilePhone')
});

export const personalIdSchema: yup.SchemaOf<IPersonalId> = yup.object({
  personalIdType: yup.string().required().label('payment.typeLabel'),
  personalIdNumber: yup
    .string()
    .when(
      ['$country', 'personalIdType'],
      // @ts-ignore: fix type error
      (country: string, idType: string, schema: StringSchema) => {
        const countryTypes = countryPersonalIds.find(
          c => c.country === country
        );
        if (countryTypes) {
          const type = countryTypes.types.find(({ name }) => name === idType);
          return type
            ? schema
                .min(type.min, 'messageError.min')
                .max(type.max, 'messageError.max')
                .matches(type.regexp, 'messageError.invalidField')
            : schema;
        }
        return schema;
      }
    )
    .required('messageError.numberRequired')
    .label('payment.number')
});

export const cardHolderSchema: yup.SchemaOf<ICardHolder> = yup.object({
  cardHolder: yup
    .string()
    .required('messageError.target')
    .label('messageError.target')
});
