import { createMuiTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';
import colors from '../styles/colors.module.scss';

const fontFamily = 'Montserrat, sans-serif';
const fontWeight = 500;

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        light: colors.dhmediumgrey,
        main: colors.dhblack200,
        dark: colors.dhblack200,
        contrastText: colors.dhwhite
      },
      secondary: {
        main: '#FFC93D'
      },
      error: {
        main: colors.dherror
      },
      success: {
        main: colors.dhgreen500
      },
      background: {
        default: '#F1F1F3'
      }
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: colors.dhwhite200,
            '&.Mui-disabled': {
              background: colors.dhgrey
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: '2px',
              borderColor: colors.dhwhite300
            },
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: colors.dhwhite300
            },
            '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
              borderColor: 'rgb(5 0 56 / .5)'
            },
            fontFamily,
            fontWeight
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily,
            fontWeight
          },
          outlined: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: '80%',
            '&$shrink': {
              maxWidth: '100%'
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: '5px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily,
            textTransform: 'none',
            letterSpacing: 0,
            borderRadius: '8px'
          },
          sizeLarge: {
            padding: '12px 40px',
            fontSize: '1rem'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: '#fff',
            backgroundColor: '#050038',
            fontSize: '0.8125rem'
          },
          arrow: {
            color: '#050038'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontFamily,
            fontWeight
          }
        }
      }
    }
  },
  esES
);

export default theme;
