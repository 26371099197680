import 'styles/globals.scss';
import React, { useEffect } from 'react';
import Error from 'next/error';
import Head from 'next/head';
import { AppProps } from 'next/app';
import { ThemeProvider } from '@material-ui/core/styles';
import LightTheme from 'themes/LightTheme';
import { useApollo } from 'graphQL/client';
import { ApolloProvider } from '@apollo/client';
import { SkeletonTheme } from 'react-loading-skeleton';
import { FeatureFlags } from 'model/enums';
import useFeatureFlags from 'hooks/useFeatureFlags';
import shallow from 'zustand/shallow';
import 'components/Forms/Forms.schema';
import Router, {useRouter} from 'next/router';
import { init } from 'utils/sentry';
import { page } from 'utils/analytics';
import { appWithTranslation } from 'next-i18next';
import { useTranslation } from 'next-i18next';
import useGeoCountry from 'hooks/useGeoCountry';
import useQueryParamsStore from 'hooks/useQueryParamsStore';
import { getPath } from 'utils/paths';

init();

// Track pages (when path changes)
let previousPathname = null;

const pageView = (path) => {
  const pathname = path.split('?')[0];
  if (previousPathname !== pathname) {
    const { name } = getPath(pathname);
    page(name);
  }
  previousPathname = pathname;
};

function CheckoutApp({
  Component,
  pageProps,
  featureFlags
}: AppProps & {
  Component: { Layout?: React.FC };
  featureFlags: FeatureFlags[];
}) {
  if (pageProps.error) {
    return (
      <Error
        statusCode={pageProps.error.statusCode}
        title={pageProps.error.message}
      />
    );
  }

  const Layout = Component.Layout ? Component.Layout : React.Fragment;

  const apolloClient = useApollo();
  const router = useRouter();

  const { t } = useTranslation('main');

  const { loadGeoCountry } = useGeoCountry();

  useEffect(() => { loadGeoCountry() }, [])

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  // Feature flag headers (for development purposes only)
  const [setFeatures] = useFeatureFlags(state => [state.setFeatures], shallow);
  useEffect(() => setFeatures(featureFlags || []), []);

  const { sanitizeParams, setParams } = useQueryParamsStore();
  useEffect(() => {
    const { hasProtectedParams, params} = sanitizeParams(router.query)
    setParams(router.query, true);
    if (hasProtectedParams) {
      router.replace(
        {
          pathname: router.pathname,
          query: params
        },
        undefined,
        { shallow: true, scroll: false }
      );
      return;
    }

    pageView(router.asPath);
  }, [router.query]);

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <title>{t('common.brandName')}</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <ThemeProvider theme={LightTheme}>
        <SkeletonTheme color="var(--dh-white6)" highlightColor="var(--dh-white5)">
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </SkeletonTheme>
      </ThemeProvider>
    </ApolloProvider>
  );
}

// DEV ONLY: Uncomment this method for feature flag development.
// This disables the ability to perform automatic static optimization, causing every page to be server-side rendered.
// CheckoutApp.getInitialProps = async (appContext: AppContext) => {
//   const appProps = await App.getInitialProps(appContext);
//   const featureFlags = getFeatureFlags(appContext.ctx.req!.headers);
//   return { ...appProps, featureFlags };
// };

// const getFeatureFlags = (headers: IncomingHttpHeaders) => {
//   const ffRegExp = new RegExp('x-acamica-.*');
//   const featureFlags = Object.keys(headers).filter(
//     (value: string) => ffRegExp.test(value) && headers[value] === 'true'
//   );
//   return featureFlags;
// };

export default appWithTranslation(CheckoutApp);
