/// <reference types="@types/segment-analytics" />
import * as Sentry from '@sentry/nextjs';

declare global {
  interface Window {
    analytics?: SegmentAnalytics.AnalyticsJS;
  }
}

export const page = (name?: string) => {
  window.analytics?.page(name);
};

export const track = (name: string, properties: any) => {
  window.analytics?.track(name, properties);
};

export const getAnonymousId = (): string => {
  try {
    return window.analytics.user().anonymousId();
  } catch {
    return '';
  }
};

export const identify = (traits: any, id?: string) => {
  if (id) {
    window.analytics?.identify(id, traits);
  } else {
    window.analytics?.identify(traits);
  }
};

export const findGeoCountry = async () => {
  try {
    const res = await fetch(process.env.NEXT_PUBLIC_GEO_COUNTRY_URL || '');
    const data = await res.text();

    const { loc } = data.split('\n').reduce((previousValue, current) => {
      const parts = current.split('=');
      const [key, value] = parts;
      if (key) previousValue[key] = value;
      return previousValue;
    }, { loc: '' });

    if (!loc) {
      Sentry.captureEvent({
        event_id: 'Geo Country Unknown',
        message: `Geo Country is not available at ${process.env.NEXT_PUBLIC_GEO_COUNTRY_URL} `,
        level: Sentry.Severity.Warning,
      });
    }

    return loc;
  } catch (e) {
    Sentry.captureException(e);
    return '';
  }
};
