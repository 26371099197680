interface IPath {
  name: string,
  path: string,
  regex: RegExp,
}

const GROUP_PAGE = {
  name: 'Group',
  path: '/products/:wareId/group',
  regex: /^\/(?:[^/]+\/)?products\/[^/]+\/group(\?.+)?$/,
};
const PAYMENT_PAGE = {
  name: 'Payment',
  path: '/products/:wareId/payment',
  regex: /^\/(?:[^/]+\/)?products\/[^/]+\/payment(\?.+)?$/,
};
const REVIEW_PAGE = {
  name: 'Review',
  path: '/products/:wareId/review',
  regex: /^\/(?:[^/]+\/)?products\/[^/]+\/review(\?.+)?$/,
};
const CONFIRM_PAGE = {
  name: 'Confirm',
  path: '/products/:wareId/confirm',
  regex: /^\/(?:[^/]+\/)?products\/[^/]+\/confirm(\?.+)?$/,
};


const PATHS:{ [key: string]: IPath } = {
  GROUP_PAGE,
  PAYMENT_PAGE,
  REVIEW_PAGE,
  CONFIRM_PAGE
};

const UNKNOWN_PATH = {
  name: undefined,
};

export const getPath = (currentPath: string) => {
  let path;

  Object.keys(PATHS).forEach((key) => {
    if (PATHS[key].regex.test(currentPath)) path = PATHS[key];
  });

  return path ?? UNKNOWN_PATH;
};
