import { cloneDeep } from '@apollo/client/utilities';
import create from 'zustand';
import { persist } from 'zustand/middleware';

type UsePaymentStoreType = {
  params: Record<string, string>;
  setParams: (value: Record<string, any>, keepParams?: boolean) => void;
  sanitizeParams: (value: Record<string, any>) => {
    hasProtectedParams: boolean,
    params: Record<string, string>
  };
  clearParams: (...params: string[]) => void;
};

const protectedParams = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'personalIdType',
  'personalIdNumber',
  'birthdate',
]

const hasProtectedQueryParams = (query: Record<string, string>) => Object.keys(query).some(q => protectedParams.includes(q))

const removeProtectedParams = (params: Record<string, string>) => {
  const copyParams = cloneDeep(params)
  protectedParams.forEach(param => {
    delete copyParams[param];
  });

  return copyParams;
}

const useQueryParamsStore = create<UsePaymentStoreType>(
  persist(
    set => ({
      params: {},
      setParams: (value, keepParams = false) => set((prev) => {
        const newParams = keepParams ? { ...prev.params, ...value } : value;

        return { params: newParams }
      }),
      sanitizeParams: (value) => {
        return {
          hasProtectedParams: hasProtectedQueryParams(value),
          params: removeProtectedParams(value)
        }
      },
      clearParams: (...values) => set((prev) => {
        for (const key of values) {
          delete prev.params[key];
        }
        return { params: prev.params }
      })
    }),
    {
      name: 'payment', // unique name
      getStorage: () => sessionStorage
    }
  )
);

export default useQueryParamsStore;
