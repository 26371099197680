import { useMemo } from 'react';
import * as Sentry from '@sentry/nextjs';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloError
} from '@apollo/client';
import { NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context'
import { persistCacheSync, SessionStorageWrapper } from 'apollo3-cache-persist';

export enum FetchPolicy {
  'cache-first' = 'cache-first',
  'network-only' = 'network-only',
  'cache-only' = 'cache-only',
  'no-cache' = 'no-cache',
  'standby' = 'standby'
}

const APOLLO_SERVER_ENDPOINT =
  process.env.NEXT_PUBLIC_GQL_ENDPOINT ||
  'https://qa-growth-api.digitalhouse.com/gql/graphql';

const link = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
      Sentry.captureException(message);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    Sentry.captureException(networkError);
  }
}).concat(
  createHttpLink({
    uri: APOLLO_SERVER_ENDPOINT
  })
);

/* const link = createHttpLink({
  uri: GRAPHQL_URL
}); */

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
    },
  };
});

const cache = new InMemoryCache();

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  if (typeof window !== 'undefined') {
    persistCacheSync({
      cache,
      storage: new SessionStorageWrapper(window.sessionStorage)
    });
  }
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // set to true for SSR
    link: authLink.concat(link),
    cache
  });
};

export const initializeApollo = (initialState: any = null) => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  // if (initialState) {
  //   // Get existing cache, loaded during client side data fetching
  //   const existingCache = _apolloClient.extract();

  //   // Restore the cache using the data passed from
  //   // getStaticProps/getServerSideProps combined with the existing cached data
  //   _apolloClient.cache.restore({ ...existingCache, ...initialState });
  // }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
};

export function useApollo() {
  const store = useMemo(() => initializeApollo(), []);
  return store;
}

export const getApolloError = (error: ApolloError) => {
  for (const e of error.graphQLErrors) {
    switch (e.extensions?.code) {
      case 'INTERNAL_SERVER_ERROR':
        return { code: 500, message: 'Ha ocurrido un error' };
    }
  }
  return { code: 404, message: error.message };
};

export default initializeApollo();
