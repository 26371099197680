import { FeatureFlags } from 'model/enums';
import create from 'zustand';
import { persist } from 'zustand/middleware';

type UseFeatureFlagsType = {
  features: FeatureFlags[];
  setFeatures: (features: FeatureFlags[]) => void;
};

const useFeatureFlags = create<UseFeatureFlagsType>(
  persist(
    set => ({
      features: [],
      setFeatures: newFeatures => set(state => ({ features: newFeatures }))
    }),
    {
      name: 'features', // unique name,
      getStorage: () => sessionStorage
    }
  )
);

export default useFeatureFlags;
