import { findGeoCountry } from "utils/analytics"
import create from "zustand";

type geoCountryType = {
  geoCountry: string, 
  loadGeoCountry: () => void
}
const useGeoCountry = create<geoCountryType>((set) => ({
  geoCountry: '',
  
  loadGeoCountry: async () => {
    const data = await findGeoCountry();
    set({ geoCountry: data });
  }
}));

export default useGeoCountry;